import { useRecentSlider } from "hooks/useGeneralSLider";
import Slider from "react-slick";
import imageUrl from "utils/ImageUrl";

export default function RecentlyAdded() {
    const sliderSetting = useRecentSlider()
    return <>
        <section className="recently-added">
            <div className="recently-head">
                <h3>Recently added</h3>
                <div className="recently-slider mt-4">
                    <Slider {...sliderSetting}>
                        {
                            Array(6).fill(0).map((item, _ind) => {
                                return <>
                                    <div className="recent-slider-box mx-3">
                                        <img src={imageUrl('akcent.png')} alt="" className="main-img" />
                                        <div className="listing-main">
                                            <ul>
                                                <li>
                                                    <img src={imageUrl('akcent.png')} alt="" />
                                                </li>
                                                <li>
                                                    <img src={imageUrl('atif.png')} alt="" />
                                                </li>
                                                <li>
                                                    <img src={imageUrl('akcent.png')} alt="" />
                                                </li>
                                            </ul>
                                            <span>10+ Place</span>
                                            <i class="fa-solid fa-ellipsis"></i>
                                        </div>
                                        <div className="desc-section">
                                            <h4>DIEGO RIMES</h4>
                                            <h5>Callente del foco</h5>
                                        </div>
                                        <div className="recent-box-foot">
                                            <p>100k plays</p>
                                            <span><i class="fa-regular fa-heart"></i>205</span>
                                        </div>
                                    </div>
                                </>
                            })
                        }


                    </Slider>
                </div>
            </div>
        </section>
    </>
}