import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import imageUrl from "../../utils/ImageUrl";
import _api from "services/_api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import useAuthenticate from "hooks/useAuthenticate";
import useAuth from "hooks/useAuth";
import { Header } from "components/New";

function ArtistProfile() {
  useAuthenticate()
  const user = useAuth();
  const navigate = useNavigate();
  const [preview, setPreview] = useState('logo')
  const [formData, setFormData] = useState({ name: '', genre: '', description: '', artistId: user?._id, image: '', audio: '' })
  const { data: genreData, isLoading: genreIsLoading, error: genreError } = useQuery(['getGenre'], () => _api.getGenre())
  const genres = genreData?.data || [];
  const { mutate, isLoading } = useMutation(_api.storeTrack, {
    onError: function (error) {
      toast.error(error.toString())
    },
    onSuccess: ({ data }) => {
      if (data.status) {
        toast.success(data.message)

        if (user.role === "artist") {
          navigate('/new-artist')
        }

      } else {
      }
    },
  });
  const onChangeHandler = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };
  const handleAudio = (e) => {
    if (e.target.files[0]) {
      setFormData((prevState) => ({ ...prevState, ['audio']: e.target.files[0] }));
    }
  }
  const handleChangeImage = e => {
    if (e.target.files[0]) {
      setFormData((prevState) => ({ ...prevState, ['image']: e.target.files[0] }));
      setPreview({ [e.target.name]: URL.createObjectURL(e.target.files[0]) })
    }
  }
  const uploadTrack = async (e) => {
    e.preventDefault();
    const form_data = new FormData();

    for (const [key, value] of Object.entries(formData)) {
      form_data.append(key, value);
    }
    mutate(form_data);
  }
  return <>
    <Header />
    <section
    >
      <form onSubmit={(e) => uploadTrack(e)} enctype="multipart/form-data">

        <div class="mt-3">
          <div class="container">
            <div className="texture-bg new-big-form mt-5 mb-5">
              <div class="profile-list">
                <div className="flex-end mb-3">
                  <h3 className="form-section-title"><span className="new-primary-text">Upload Your Track</span></h3>
                </div>
              </div>
              <div class="row gy-3">
                <div class="col-lg-4">
                  <div class="image-profile ">
                    <label for="image" className="custom-label mb-2">Track Cover</label>
                    <img
                      src={preview['image'] != undefined ? preview['image'] : imageUrl("image-upload.png")}
                      alt="image-upload.png"
                      class="upload-image"
                    />
                    <input type="file" accept=".jpeg, .jpg, .png"  name="image" className="d-none input-image" id="image" onChange={(e) => handleChangeImage(e)} />
                    <button type="button" className="new-custom-btn new-primary-btn mt-3" 
                    onClick={(e) => document.getElementById('image').click()
                  }
                  >UPLOAD COVER</button>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div class="form-group signup-box mb-3">
                        <label for="name" className="custom-label">Track Title</label>
                        <input
                          type="text"
                          class="form-control login-form"
                          placeholder="Track Name"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          id="name"
                          onChange={onChangeHandler}
                          required />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                    <div class="form-group signup-box mb-3">
                    <label for="genre" className="custom-label">Pick a Genre</label>
                        <select
                          class="form-select login-form"
                          aria-label="Default select example"
                          id="genre"
                          onChange={onChangeHandler}
                          required>
                          <option selected disabled value={''}>Pick a Genre</option>
                          {
                            genres?.length > 0 ?
                              <>
                                {genres?.map((item, _ind) => {
                                  return <>
                                    <option value={item?.name}>{item?.name}</option>
                                  </>
                                })}
                              </>
                              :
                              <></>
                          }
                        </select>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group signup-box mb-3">
                    <label for="description" className="custom-label">Track description</label>
                        <textarea
                      class="form-control login-form"
                      placeholder="Descriptiion"
                      id="description"
                      onChange={onChangeHandler}
                      required></textarea>
                      </div>
                    </div>
                    <div className="col-12">
                    <div className="form-group signup-box mb-3">
                    <label for="audio" className="custom-label">Upload Audio</label>
                    <input
                      accept=".mp3, .mpeg" 
                      type="file"
                      class="form-control login-form input-pl-0"
                      placeholder="Track Name"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      id="audio"
                      onChange={handleAudio}
                      required />
                  </div>
                    </div>
                    <div className="col-12">
                    <div class="artist-btn primary-btn my-4">
                      {
                        isLoading ?
                          <button type="submit" disabled className="new-custom-btn new-primary-btn"><span>Loading...</span> </button>
                          :
                          <button type="submit" className="new-custom-btn new-primary-btn"><span>UPLOAD</span></button>
                      }
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  </>
}

export default ArtistProfile;
