import _api from "../services/_api"
import {
  DashboardBanner
} from "../components/index";

function Dashboard() {

  return (
    <>
      <DashboardBanner />
    </>
  );
}

export default Dashboard;
