import { createSlice } from "@reduxjs/toolkit";
import reduxApis from "redux/api";

const trackSlice = createSlice({
    name: "track",
    initialState: {
        loading: false,
        track: null,
        isPlay : false,
        currentSong : 0
    },
    reducers: {
            setIsPlay: (state, action) => {
              state.isPlay = action.payload;
            },
            setCurrentSong : (state, action) => {
              state.currentSong = action.payload;
            },
          },

    extraReducers: {
        [reduxApis.getUniqueTrack.pending]: (state, action) => {
            state.loading = true;
        },
        [reduxApis.getUniqueTrack.fulfilled]: (state, action) => {
            state.loading = false;
            state.track = action.payload;
        },
        [reduxApis.getUniqueTrack.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});
export const { setTrack, setIsPlay, setCurrentSong } = trackSlice.actions;

export default trackSlice.reducer;
